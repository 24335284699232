import React, { useEffect } from "react"

import Layout from "../components/Layout/Layout"
import SEO from "../components/SEO/SEO"
import OfferingDesc from "../components/Offering/OfferingDesc"
import OfferingList from "../components/Offering/OfferingList/OfferingList"
import BottomNav from "../components/Offering/BottomNav/BottomNav"

import { AppProvider } from "../context/AppContext"

const MarchWineOffering = () => {
  useEffect(() => {
    const scriptTag = document.createElement("script")

    scriptTag.src =
      "https://cdn.jsdelivr.net/npm/mailtoui@1.0.3/dist/mailtoui-min.js"
    // scriptTag.async = true
    scriptTag.dataset.options = '{ "title": "Open order form with" }'

    document.body.appendChild(scriptTag)
    return () => {
      document.body.removeChild(scriptTag)
    }
  }, [])
  return (
    <AppProvider>
      {/* <Helmet>
        <script src="https://cdn.jsdelivr.net/npm/mailtoui@1.0.3/dist/mailtoui-min.js"></script>
      </Helmet> */}
      <Layout>
        <main>
          <OfferingDesc />
          <OfferingList />
          <BottomNav />
        </main>
      </Layout>
    </AppProvider>
  )
}

export default MarchWineOffering
